<template>
    <div class="content">
        <div class="filter">
            <div>选项</div>
            <button @click="onCalculate">分析</button>
            <!-- <button @click="deleteDuplicate">排序去重</button> -->

            <div>
                <!-- <div>
                    <div>红球定胆</div>
                    <div>
                        <a-checkbox-group
                            v-model="redSets"
                            :options="redSetsOptions"
                            @change="onChange"
                        />
                    </div>
                </div> -->

                <div>
                    <div>红球定位定胆</div>
                    <div>
                        <div>
                            1
                            <a-select
                                v-model="redSet1"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div>
                            2
                            <a-select
                                v-model="redSet2"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>

                        <div>
                            3
                            <a-select
                                v-model="redSet3"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>

                        <div>
                            4
                            <a-select
                                v-model="redSet4"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div>
                            5
                            <a-select
                                v-model="redSet5"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div>
                            6
                            <a-select
                                v-model="redSet6"
                                mode="multiple"
                                style="width: 200px"
                                placeholder="Please select"
                                @change="handleChange"
                            >
                                <a-select-option
                                    v-for="i in allRedOption"
                                    :key="i"
                                >
                                    {{ i }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>

                <div>
                    <div>红球定位杀尾</div>
                    <button @click="filterNotSet">杀尾</button>

                    <div>
                        1
                        <a-select
                            v-model="notTail1"
                            mode="multiple"
                            style="width: 200px"
                            placeholder="Please select"
                            @change="handleChange"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        2
                        <a-select
                            v-model="notTail2"
                            mode="multiple"
                            style="width: 200px"
                            placeholder="Please select"
                            @change="handleChange"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        3
                        <a-select
                            v-model="notTail3"
                            mode="multiple"
                            placeholder="Please select"
                            style="width: 200px"
                            @change="handleChange"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        4
                        <a-select
                            v-model="notTail4"
                            mode="multiple"
                            placeholder="Please select"
                            style="width: 200px"
                            @change="handleChange"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        5
                        <a-select
                            v-model="notTail5"
                            mode="multiple"
                            style="width: 200px"
                            placeholder="Please select"
                            @change="handleChange"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        6
                        <a-select
                            v-model="notTail6"
                            mode="multiple"
                            placeholder="Please select"
                            @change="handleChange"
                            style="width: 200px"
                        >
                            <a-select-option
                                v-for="i in notTailOptions"
                                :key="i"
                            >
                                {{ i }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div>
                    <div>和值范围</div>
                    <button @click="filterSum">和值筛选</button>
                    <div>
                        <a-input-number
                            id="inputNumber"
                            v-model="summin"
                            @change="onChange"
                        />
                        最小：{{ summin }}
                        <a-input-number
                            id="inputNumber"
                            v-model="summax"
                            @change="onChange"
                        />
                        最大：{{ summax }}
                    </div>
                </div>
                <div>
                    <div>求证</div>
                    <button @click="onVerify">求证</button>

                    <a-input
                        placeholder="求证"
                        allow-clear
                        @change="onVerify"
                        v-model="verify"
                    />
                </div>
                <div>
                    <div>红球杀号</div>
                    <button @click="onCalculate2">杀号</button>
                    <div>
                        <a-checkbox-group
                            v-model="redDiscard"
                            :options="redDiscardOptions"
                            @change="onChange"
                        />
                    </div>
                </div>
                <div>
                    <!-- <div>开奖查询</div> -->
                    <div></div>
                </div>
            </div>
        </div>
        <div class="result">
            <div>结果: {{ results.length }} {{ more ? '+' : '' }}</div>
            <div>
                <div v-for="(item, index) in results" :key="index">
                    <div style="display: flex; justify-content: center">
                        {{ item.join(',') }}
                        <a-icon
                            type="delete"
                            @click="deleteResult(index, item)"
                        />
                    </div>
                    <!-- <a-tag closable @close="deleteResult(index, item)"> </a-tag> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const allRedOption = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
];

const notTailOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export default {
    name: 'HelloWorld',
    props: {
        msg: String,
    },
    data() {
        return {
            summin: 0,
            summax: 0,
            redSets: [],
            // redSet1: ['1', '2', '3', '4'],
            // redSet2: ['3', '4', '5'],
            // redSet3: ['6', '4', '5', '7'],
            // redSet4: ['1', '4', '5', '7'],
            // redSet5: ['18', '17', '10', '24'],
            // redSet6: ['29', '33', '13', '28'],
            redSet1: ['1', '2','3','4','5','6', '7', '8', '9', '10', '11','12'],
            redSet2: ['2','3','4','5','6', '7', '8', '9', '10', '11','12', '13', '14', '15', '16', '17', '18', '19'],
            redSet3: ['5','6', '7', '8', '9', '10', '11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            redSet4: ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
            redSet5: ['17','18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32'],
            redSet6: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33'],
            redSetsOptions: Object.assign([], allRedOption),
            redDiscard: [],
            redDiscardOptions: Object.assign([], allRedOption),
            notTailOptions,
            allRedOption,
            notTail1: [],
            notTail2: [],
            notTail3: [],
            notTail4: [],
            notTail5: [],
            notTail6: [],
            verify: '',
            results: [],
            result: [],
            more: false,
        };
    },
    mounted() {},
    methods: {
        onChange() {},
        handleChange() {
        },
        onVerify() {
            let redOptions = this.verify.split(',');
            redOptions = redOptions.map((val) => parseInt(val).toString());
            redOptions.sort((a, b) => a - b);
            this.results = this.results.filter((val) => {
                for (let i = 0; i < val.length; i++) {
                    const op = val[i];
                    if (redOptions.indexOf(op) === -1) {
                        return false;
                    }
                }
                return true;
            });
        },
        onCalculate() {
            this.calculateRedSetAll();
        },
        onCalculate2() {
            this.results = this.results.filter((val) => {
                for (let i in this.redDiscard) {
                    const discard = this.redDiscard[i];
                    if (val.indexOf(discard) !== -1) {
                        return false;
                    }
                }
                return true;
            });
        },
        deleteResult(index) {
            this.results.splice(index, 1);
        },
        deleteDuplicate() {
            this.results = this.results.map((val) => val.sort((a, b) => a - b));
            this.results = this.results.filter((val) => {
                for (let i = 0; i < val.length - 1; i++) {
                    const item1 = val[i];
                    for (let j = i + 1; j < val.length; j++) {
                        const item2 = val[j];
                        if (item1 === item2) {
                            return false;
                        }
                    }
                }
                return true;
            });

            let resultStrings = this.results.map((val) => val.join(','));
            resultStrings.sort((a, b) => {
                if (a > b) {
                    return 1;
                } else {
                    return -1;
                }
            });
            resultStrings = resultStrings.filter((val, index) => {
                if (index === resultStrings.length - 1) {
                    return true;
                } else {
                    if (val === resultStrings[index + 1]) {
                        return false;
                    } else {
                        return true;
                    }
                }
            });
            this.results = resultStrings.map((val) => val.split(','));
        },
        filterNotSet() {
            this.results = this.results.filter((val) => {
                if (this.notTail1.length > 0) {
                    const tail = val[0].slice(-1);
                    if (this.notTail1.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                if (this.notTail2.length > 0) {
                    const tail = val[1].slice(-1);
                    if (this.notTail2.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                if (this.notTail3.length > 0) {
                    const tail = val[2].slice(-1);
                    if (this.notTail3.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                if (this.notTail4.length > 0) {
                    const tail = val[3].slice(-1);
                    if (this.notTail4.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                if (this.notTail5.length > 0) {
                    const tail = val[4].slice(-1);
                    if (this.notTail5.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                if (this.notTail6.length > 0) {
                    const tail = val[5].slice(-1);
                    if (this.notTail6.indexOf(tail) !== -1) {
                        return false;
                    }
                }
                return true;
            });
        },
        filterSum() {
            this.results = this.results.filter((val) => {
                if (this.summin && this.summax && this.summax > this.summin) {
                    const sum = val.reduce((a, b) => parseInt(a) + parseInt(b));
                    if (this.summax < sum) {
                        return false;
                    }
                    if (this.summin > sum) {
                        return false;
                    }
                }
                return true;
            });
        },
        calculateRedSetAll() {
            let redSetAll = [
                this.redSet1,
                this.redSet2,
                this.redSet3,
                this.redSet4,
                this.redSet5,
                this.redSet6,
            ].flatMap((a) => a);
            redSetAll.sort((a, b) => a - b);
            // 去重
            redSetAll = redSetAll.filter((val, index) => {
                if (index === redSetAll.length - 1) {
                    return true;
                } else {
                    if (val === redSetAll[index + 1]) {
                        return false;
                    } else {
                        return true;
                    }
                }
            });
            this.results = [];
            this.calculateRedSetAllloop(redSetAll, 6);
            this.results = this.results.filter((val) => {
                if (this.redSet1.length > 0) {
                    if (this.redSet1.indexOf(val[0]) === -1) {
                        return false;
                    }
                }
                if (this.redSet2.length > 0) {
                    if (this.redSet2.indexOf(val[1]) === -1) {
                        return false;
                    }
                }
                if (this.redSet3.length > 0) {
                    if (this.redSet3.indexOf(val[2]) === -1) {
                        return false;
                    }
                }
                if (this.redSet4.length > 0) {
                    if (this.redSet4.indexOf(val[3]) === -1) {
                        return false;
                    }
                }
                if (this.redSet5.length > 0) {
                    if (this.redSet5.indexOf(val[4]) === -1) {
                        return false;
                    }
                }
                if (this.redSet6.length > 0) {
                    if (this.redSet6.indexOf(val[5]) === -1) {
                        return false;
                    }
                }

                return true;
            });
        },
        calculateRedSetAllloop(options, maxnum, currentLevel, result) {
            if (currentLevel === undefined) {
                currentLevel = 0;
            }
            if (result === undefined) {
                result = [];
            }
            let forOptions = options;
            for (let index = 0; index < forOptions.length; index++) {
                result[currentLevel] = forOptions[index];
                const newOptions = options.slice(1 + index);
                if (currentLevel + 1 < maxnum) {
                    this.calculateRedSetAllloop(
                        newOptions,
                        maxnum,
                        currentLevel + 1,
                        result,
                    );
                } else {
                    this.results.push(Object.assign([], result));
                    // if (this.results.length > 10000) {
                    //     return;
                    // }
                }
            }
        },

        calculate() {
            let r1s = this.redSet1.sort((a, b) => a - b);
            let r2s = this.redSet2.sort((a, b) => a - b);
            let r3s = this.redSet3.sort((a, b) => a - b);
            let r4s = this.redSet4.sort((a, b) => a - b);
            let r5s = this.redSet5.sort((a, b) => a - b);
            let r6s = this.redSet6.sort((a, b) => a - b);
            this.results = [];

            for (let i1 = 0; i1 < r1s.length; i1++) {
                const s1 = r1s[i1];
                for (let i2 = 0; i2 < r2s.length; i2++) {
                    const s2 = r2s[i2];
                    if (s2 <= s1) continue;
                    for (let i3 = 0; i3 < r3s.length; i3++) {
                        const s3 = r3s[i3];
                        if (s3 <= s2 || s2 <= s1) continue;
                        for (let i4 = 0; i4 < r4s.length; i4++) {
                            const s4 = r4s[i4];
                            if (s4 <= s3 || s3 <= s2 || s2 <= s1) continue;
                            for (let i5 = 0; i5 < r5s.length; i5++) {
                                const s5 = r5s[i5];
                                if (
                                    s5 <= s4 ||
                                    s4 <= s3 ||
                                    s3 <= s2 ||
                                    s2 <= s1
                                )
                                    continue;
                                for (let i6 = 0; i6 < r6s.length; i6++) {
                                    const s6 = r6s[i6];
                                    if (
                                        s6 <= s5 ||
                                        s5 <= s4 ||
                                        s4 <= s3 ||
                                        s3 <= s2 ||
                                        s2 <= s1
                                    )
                                        continue;

                                    const result = [s1, s2, s3, s4, s5, s6];
                                    this.results.push(result);
                                    if (this.results.length > 10000) {
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.content {
    display: flex;

    .filter {
        width: 50%;
    }

    .result {
        width: 50%;
    }
}
</style>
